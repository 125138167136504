import React from "react";
import "../../css/Pages.scss";

import { PublicPageProps } from "comps/pages";

interface IProps extends PublicPageProps {}

const GoodBye: React.FC<IProps> = () => {
  return (
    <div className="Public">
      <div className="Page">
        <div className="content">
          <div className="logo">
            <img src="/img/logo.png" alt="illuminate" />
          </div>
          <div className="form">
            <h1 style={{ fontSize: "1.5rem" }}>
              Thank you for being a part of illuminate
            </h1>
            <p className="mb-2">
              As of February 28, 2025, we have officially discontinued the
              service. We understand this may be an adjustment, and we truly
              appreciate your support over the years.{" "}
            </p>

            <p className="mb-2">
              All users were notified via email, and account administrators
              received their data before the transition. If you have any
              remaining questions, please refer to your account admin for more
              details. We're grateful to have been a part of your journey.
            </p>

            <p className="mb-2">
              For additional information, contact{" "}
              <a href="mailto:hello@blazerconsulting.com">
                hello@blazerconsulting.com
              </a>
            </p>
          </div>
        </div>
        <div style={{ height: "100vh" }}>
          <img
            src="/img/bg_2.jpg"
            alt="illuminate"
            style={{ height: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export { GoodBye };
